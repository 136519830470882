<template>
    <div>
        <el-dialog
            title="账期添加"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            top="2vh"
            style="margin-bottom:1vh"
            width="1200px">
            <div class="a-flex-rfsc" slot="title">
                <le-select-remote-search label="项目" class="income" v-model="pageParam.params.incomeId" :options="optionsincomeDistri" placeholder="请选择（可输入搜索）" />
                <div class="a-flex-rfsc a-ml-30">
                    <span style="padding-right: 20px" class="a-fs-14 a-c-666">日期</span>
                    <el-date-picker
                        :disabled="isEditStartDate"
                        v-model="pageParam.params.startDate"
                        type="date"
                        :picker-options="pickerOptionsStart"
                        :editable="false"
                        value-format="yyyy-MM-dd"
                        placeholder="开始日期">
                    </el-date-picker>
                    <span class="a-mlr-12">-</span>
                    <el-date-picker
                        v-model="pageParam.params.endDate"
                        type="date"
                        value-format="yyyy-MM-dd"
                        :editable="false"
                        :picker-options="pickerOptions"
                        placeholder="结束日期">
                    </el-date-picker>
                </div>
                <div class="a-ml-10"><el-button type="primary" @click="zqPreview">账期预览</el-button></div>
            </div>
            <le-pagview ref="financeZQYL" @setData="setTableData" :pageParam="pageParam" :pagerCount="5">
                <div class="a-flex-rfsfs" style="align-items: stretch;">
                    <el-table ref="financeZQYLList" :data="tableData" height="55vh" :highlight-current-row="true" style="width: 100%">
                        <el-table-column prop="orderSn" label="订单编号" min-width="180">
                            <template slot-scope="{ row }">
                                <span>{{ row.orderSn?row.orderSn:'-' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="orderType" label="订单类型" min-width="100">
                            <template slot-scope="{ row }">
                            <span>{{ row.orderType | initDic(orderTypeDic) }}</span>
                        </template>
                        </el-table-column>
                        <el-table-column prop="incomePrice" label="入账金额" min-width="100">
                            <template slot-scope="{ row }">
                                <span>￥{{ util.numFormat(row.incomePrice) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="createTime" label="分账时间" min-width="180">
                            <template slot-scope="{ row }">
                                <span>{{ row.createTime?row.createTime:'-'  }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <choose-company-pannel :value.sync="currentCompany" :options="companyList"></choose-company-pannel>
                </div>
                
            </le-pagview>
            <div slot="footer" v-if="companyList.length" class="a-flex-rfsc">
                <div class="a-flex-rfsc a-flex-1">
                    <span>入账总金额：￥{{ allPrice }}</span>
                </div>
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">生成账期</el-button>
            </div>
            <div slot="footer" v-if="!companyList.length">
                <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    let _this
    import util from '../../../../src/utils/util'
    import chooseCompanyPannel from './chooseCompanyPannel.vue'
    export default {
        components:{
            chooseCompanyPannel
        },
        data() {
            return {
                dialogVisible: false,
                util: util,
                pageParam: {
                    url: this.$Config.apiUrl.showPaymentDays,
                    method: "post",
                    params: {
                        incomeId: '',
                        startDate: '',
                        endDate: '',
                        companyId: '',
                    },
                    freshCtrl: -1,
                },
                tableData: [],
                optionsincomeDistri: {
                    url: this.$Config.apiUrl.incomeDistriList,
                    method: "post",
                    params: {
                        incomeType: 2
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                },//项目数据
                pickerOptionsStart: {
                    disabledDate:(time) => {
                        if(this.pageParam.params.endDate) {
                            return time.getTime() > this.$getDay.getTimeUnix(this.pageParam.params.endDate) || time.getTime() > Date.now() - 8.64e7
                        }else{
                            return time.getTime() > Date.now() - 8.64e7
                        }
                        
                    },
                },
                pickerOptions: {
                    disabledDate:(time) => {
                        if(this.pageParam.params.startDate) {
                            return time.getTime() < this.$getDay.getTimeUnix(this.pageParam.params.startDate) || time.getTime() > Date.now() - 8.64e7
                        }else{
                            return time.getTime() > Date.now() - 8.64e7
                        }
                        
                    },
                },
                isEditStartDate: false,
                currentCompany: '',
                companyList: [],
                orderTypeDic: [],//订单类型
            };
        },
        watch:{
            dialogVisible (val) {
                this.pageParam.params.incomeId = ''
                this.pageParam.params.startDate = ''
                this.pageParam.params.endDate = ''
                this.tableData = []
                this.companyList = []
            },
            currentCompany: {
                deep: true,
                handler (val) {
                    if(val){
                        this.pageParam.params.companyId = val.companyId
                        this.$refs['financeZQYL'].pageNum = 1
                        this.pageParam.freshCtrl++
                    }
                },
            },
            'pageParam.params.incomeId': {
                deep: true,
                handler (val) {
                    if(val) {
                        this.pageParam.params.endDate = ''
                        this.getIncomDate()
                        this.currentCompany = ''
                        this.companyList = []
                        this.tableData = []
                    }
                }
            }
        },
        computed:{
            maxHeight () {
                let viewH = document.body.clientHeight
                return Number(viewH) * 0.5
            },
            allPrice () {
                let price = 0
                this.companyList.map(res=>{
                    price += (res.incomePriceTotal?res.incomePriceTotal:0)
                })
                return price?(price/100).toFixed(2):0.00
            }
        },
        created () {
            this.$getDic('incomeOrderType').then(res=>{ this.orderTypeDic = res; })
        },
        mounted () {
            _this = this
        },
        methods:{
            setTableData(data, countData) {
                this.tableData = data;
                
            },
            getIncomDate () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getStartDateIncome,
                    params: {
                        incomeId: this.pageParam.params.incomeId,
                    },
                })
                .then(res => {
                    if(res.result.code == 0){
                        this.pageParam.params.startDate = res.result.data?res.result.data:''
                        if(this.pageParam.params.startDate){
                            this.isEditStartDate = true
                        }else{
                            this.isEditStartDate = false
                        }
                    }
                })
                .catch((error) => {
                    this.$message.error(error.result.message)
                });
            },
            zqPreview () {
                if(!this.pageParam.params.incomeId){
                    this.$message.error('请先选择项目')
                    return
                }
                if(!this.pageParam.params.startDate){
                    this.$message.error('请先选择开始日期')
                    return
                }
                if(!this.pageParam.params.endDate){
                    this.$message.error('请先选择结束日期')
                    return
                }
                this.$Axios._post({
                    url: this.$Config.apiUrl.showPaymentCountDays,
                    params: {
                        incomeId: this.pageParam.params.incomeId,
                        startDate: this.pageParam.params.startDate,
                        endDate: this.pageParam.params.endDate
                    },
                })
                .then(res => {
                    if(res.result.code == 0){
                        this.companyList = res.result.data.count.map(item=>{
                            return {
                                label: item.companyName,
                                value: item.companyId,
                                rightTag: '入账金额 ￥' + (item.incomePriceTotal/100).toFixed(2),
                                ...item
                            }
                        })
                        this.currentCompany = this.companyList[0]
                    }
                })
                .catch((error) => {
                    this.$message.error(error.result.message)
                });

            },
            chooseCompany (datas) {

            },
            submit () {
                this.$confirm('是否确认生成账期？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.addPaymentDays,
                        params: {
                            incomeId: this.pageParam.params.incomeId,
                            startDate: this.pageParam.params.startDate,
                            endDate: this.pageParam.params.endDate,
                        },
                    })
                    .then(res => {
                        if(res.result.code == 0){
                            this.dialogVisible = false
                            this.$message.success('操作成功')
                            this.$emit('handlerSuccess')
                        }
                    })
                    .catch((error) => {
                        this.$message.error(error.result.message)
                    });
                }).catch(_=>{ })
                
            },
        }
    }
</script>

<style lang="scss" scoped>
    .income{
        /deep/ .s-search-label{
            width: auto !important;
        }
    }
    /deep/ .el-dialog__body{
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
    }
</style>