<template>
    <div>
        <el-dialog
            title="平账"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="600px">
            <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                <el-form-item label="类型" prop="balanceIncomeType">
                    <el-select v-model="form.balanceIncomeType">
                        <el-option v-for="item in balanceIncomeTypeDic"
                            :key="item.value"
                            :label="item.text"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="种类" prop="changeType">
                    <el-select v-model="form.changeType" >
                        <el-option v-for="item in changeTypeDic"
                            :key="item.value"
                            :label="item.text"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="金额" prop="price">
                    <jd-input-price v-model="form.price" :maxlength='11' style="width:240px"></jd-input-price>
                </el-form-item>
                <el-form-item label="日期" prop="countDate">
                    <el-date-picker
                        v-model="form.countDate"
                        type="date"
                        :editable="false"
                        :picker-options="pickerOptions"
                        value-format="yyyy-MM-dd"
                        placeholder="开始日期">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                id: '',
                startDate: '',
                endDate: '',
                form: {
					balanceIncomeType: '',
                    changeType: '',
                    price: '',
                    countDate: '',
                },
                rules: {
                    balanceIncomeType: [{required: true, message:'请选择类型', trigger: 'change'}],
					changeType: [{required: true, message:'请选择种类', trigger: 'change'}],
					price: [{required: true, message:'请输入金额', trigger: 'blur'}],
					countDate: [{required: true, message:'请选择日期', trigger: 'change'}],
                },
                pickerOptions: {
                    disabledDate:(time) => {
                        return time.getTime() > this.$getDay.getTimeUnix(this.endDate) || time.getTime() < this.$getDay.getTimeUnix(this.startDate)
                    },
                },
                balanceIncomeTypeDic: [],
                changeTypeDic: [{
                    text: '增项',
                    value: 1
                },{
                    text: '减项',
                    value: -1
                }]
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                }
            }
        },
        created () {
            this.$getDic('balanceIncomeType').then(res=>{ this.balanceIncomeTypeDic = res; })
        },
        methods:{
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let reqData = {
                            balanceIncomeType: this.form.balanceIncomeType,
                            changeType: this.form.changeType,
                            price: Number((this.form.price*100).toFixed(0)),
                            id: this.id,
                            countDate: this.form.countDate 
                        }
                        this.$confirm('是否确认提交？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            this.$Axios._post({
                                url: this.$Config.apiUrl.backDoBalanceDays,
                                params: reqData,
                            })
                            .then(res => {
                                if(res.result.code == 0){
                                    this.dialogVisible = false
                                    this.$message.success('操作成功')
                                    this.$emit('handlerSuccess');
                                }
                            })
                            .catch((error) => {
                                this.$message.error(error.result.message)
                            });
                        }).catch(_=>{ })
                    }
                })
                
            },

        }
    }
</script>