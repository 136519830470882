<template>
    <div class="a-flex-cfsfs">
        <el-card class="a-ml-20" style="width: 400px;height:100%">
            <div slot="header" class="clearfix">
                <span>请选择查询商户</span>
            </div>
            <div class="chooseList">
                <div class="a-flex-rsbfs" 
                    :class="item.checked?'a-c-blue':''" 
                    v-for="(item,index) in optionsList" 
                    :key="index" 
                    style="margin-bottom: 18px;" 
                    @click="chooseCompany(item)">
                    <div class="a-flex-rfsfs">
                        <div style="min-width: 16px"><i class="el-icon-success a-fs-14 a-c-blue" style="line-height:20px" v-show="item.checked"></i></div>
                        <span style="line-height: 20px" class="font-point a-ml-5">{{ item.label }}</span>
                    </div>
                    <span v-if="item.rightTag" style="white-space:nowrap;line-height: 20px;padding-left: 10px;">{{ item.rightTag }}</span>
                </div>
                <div class="a-flex-rcc a-c-999" v-if="!optionsList || !optionsList.length">暂无数据</div>
            </div>
        </el-card>
    </div>
</template>

<script>
    // options = [{
    //     lebel: '',
    //     value: ''
    // }]
    export default {
        data () {
            return {
                optionsList: []
            }
        },
        props:{
            value: {
                require: false
            },
            multiple: {
                default: false
            },
            options: {
                default: []
            }
        },
        watch: {
            options:{
                immediate: true,
                deep: true,
                handler (val) {
                    if(val && val instanceof Array){
                        this.optionsList = val.map((item,index)=>{
                            if(this.value && this.value instanceof Array){
                                this.value.map((ite,idx)=>{
                                    if(item.value == ite.value){
                                        return {
                                            ...item,
                                            checked: true
                                        }
                                    }else{
                                        return {
                                            ...item,
                                            checked: false
                                        }
                                    }
                                })
                            }else{
                                if(this.value && item.value == this.value.value){
                                    return {
                                        ...item,
                                        checked: true
                                    }
                                }else{
                                    return {
                                        ...item,
                                        checked: false
                                    }
                                }
                            }


                            // return {
                            //     ...item,
                            //     checked: false
                            // }
                        })
                    }else{
                        this.optionsList = []
                    }
                }
            },
        },
        mounted () {
            this.$nextTick(()=>{
                this.optionsList.map((item,index)=>{
                    if(this.value && this.value instanceof Array){
                        this.value.map((ite,idx)=>{
                            if(item.value == ite.value){
                                item.checked = true
                            }
                        })
                    }else{
                        if(this.value && item.value == this.value.value){
                            item.checked = true
                        }
                    }
                })
            })
        },
        methods:{
            chooseCompany (datas) {
                let chooseData 
                if(this.multiple){
                    if(datas.checked){
                        datas.checked = false
                    }else{
                        datas.checked = true
                    }
                    chooseData = this.optionsList.filter(res=>{
                        return res.checked == true
                    })
                }else{
                    this.optionsList = this.optionsList.map((item,index)=>{
                        let checked = false
                        if(datas.value == item.value){
                            checked = true
                        }else{
                            checked = false
                        }
                        return {
                            ...item,
                            checked: checked
                        }
                    })
                    chooseData = this.optionsList.find(res=>{
                        return res.checked == true
                    })
                }
                this.$emit('update:value',chooseData)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .chooseList{
        overflow: visible;
    }
</style>