<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">账期管理</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-plus" type="primary" @click="zqAdd">账期添加</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <div class="a-flex-rfsc">
                    <el-select v-model="pageParam.params.timeType" placeholder="请选择" style="width: 130px">
                        <el-option
                            label="账期开始时间"
                            :value="1">
                        </el-option>
                        <el-option
                            label="账期结束时间"
                            :value="2">
                        </el-option>
                    </el-select>
                    <le-date-range class="companySelect" label="" :pickerOptions="pickerOptions" :minDate.sync="pageParam.params.startDate" :maxDate.sync="pageParam.params.endDate" valueFormat="yyyy-MM-dd HH:mm:ss" />
                </div>
                <le-company-under-select  label="商户" :multiple="true" :collapse="true" v-model="pageParam.params.companyId" placeholder="请选择（可输入搜索）"></le-company-under-select>
                <le-select-remote-search label="项目" v-model="pageParam.params.incomeId" :options="optionsincomeDistri" placeholder="请选择（可输入搜索）" />
            </le-search-form>
            <le-pagview ref="financeZQ" @setData="setTableData" :pageParam="pageParam" :pagerCount="5" :isFixed="true" :tableRef="$refs.financeZQList">
                <el-table ref="financeZQList" :data="tableData" :highlight-current-row="true" @filter-change='filterFun' v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="companyName" label="商户" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.companyName?row.companyName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="paymentName" label="账期名称" min-width="180">
                        <template slot-scope="{ row }">
                            <span class="font-point a-c-blue" @click="editZQName(row)">{{ row.paymentName?row.paymentName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="incomePrice" label="分账金额" min-width="100">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.incomePrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="startDate" label="账期开始时间" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.startDate?row.startDate:'-'  }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="endDate" label="账期结束时间" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.endDate?row.endDate:'-'  }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="paymentType" 
                        label="类型" 
                        min-width="103">
                        <template slot-scope="{ row }">
                            <span>{{ row.incomeType | initDic(incomeTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="incomeStatus" 
                        label="账期状态" 
                        min-width="103">
                        <template slot-scope="{ row }">
                            <span>{{ row.incomeStatus | initDic(incomeStatusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" min-width="75" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="getZQInfo(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="更多操作" placement="top">
                                <el-dropdown placement="bottom-start" @command="moreOperation" v-if="scope.row.incomeStatus == 0">
                                    <img src="../../assets/icon/more-operation.png" class="a-wh-16" />
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item 
                                            v-for="item in optionsMoreOperation" 
                                            :command='item.value+"#"+scope.$index' 
                                            :key="item.value" 
                                            :value="item.value">{{ item.label }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                    
                                </el-dropdown>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
        <zq-add ref="zqAdd" @handlerSuccess="addZQSuccess"></zq-add>
        <finance-pz ref="financePZ" @handlerSuccess="pzSuccess"></finance-pz>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    import FinancePz from './components/finance-pz.vue'
    import zqAdd from './components/zq-add.vue'
    export default {
        components: { zqAdd, FinancePz },
        data () {
            return {
                tableData: [],
                util: util,
                pageParam: {
                    url: this.$Config.apiUrl.backIncomePaymentDays,
                    method: "post",
                    params: {
                        companyId: [],
                        endDate: "",
                        incomeId: '',
                        startDate: "",
                        timeType: 1
                    },
                    freshCtrl: 1,
                },
                choiceDate: '',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime()
                        if (maxDate) this.choiceDate = ''
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate) {
                            const one = 92 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }else{
                            return ''
                        }
                    },
                },
                optionsincomeDistri: {
                    url: this.$Config.apiUrl.incomeDistriList,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                },//项目数据
                optionsMoreOperation: [{
                    label: "平账",
                    value: 1,
                }, {
                    label: "入账",
                    value: 2,
                }, {
                    label: "删除",
                    value: 3,
                }],
                incomeTypeDic: [],
                incomeStatusDic: []
            }
        },
        created () {
            this.$getDic('incomeStatus').then(res=>{ this.incomeStatusDic = res; })
            this.$getDic('incomeType',).then(res=>{ this.incomeTypeDic = res; })
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods: {
            //获取设备列表
            setTableData(data) {
                this.tableData = data;
            },
            handlerRest() {
                this.pageParam.params = {
                    companyId: [],
                    endDate: "",
                    incomeId: '',
                    startDate: "",
                    timeType: 1
                };
                this.$refs['financeZQList'].clearFilter()
                this.handlerSearch()
            },
            handlerSearch() {
                if(this.$getDay.enumerateDaysBetweenDates(this.pageParam.params.startDate,this.pageParam.params.endDate).length > 31){
                    this.$message.error('最大查询范围31天')
                    return
                }
                this.$refs['financeZQ'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            moreOperation (commands) {
                let command = commands.split('#')[0]
                let index = commands.split('#')[1]
                switch (command) {
                    case '1':
                        this.$refs['financePZ'].dialogVisible = true
                        this.$refs['financePZ'].id = this.tableData[index].id
                        this.$refs['financePZ'].startDate = this.tableData[index].startDate
                        this.$refs['financePZ'].endDate = this.tableData[index].endDate
                        this.$refs['financePZ'].form.countDate = this.tableData[index].endDate
                        break;
                    case '2':
                        this.$confirm('是否确认入账“' + this.tableData[index].paymentName + '”？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            this.$Axios._post({
                                url: this.$Config.apiUrl.backDoIncomeDays,
                                params: {
                                    id: this.tableData[index].id
                                },
                            })
                            .then(res => {
                                if(res.result.code == 0){
                                    this.$message.success('操作成功')
                                    this.pageParam.freshCtrl++;
                                }
                            })
                            .catch((error) => {
                                this.$message.error(error.result.message)
                            });
                        }).catch(_=>{ })
                        break;
                    case '3':
                    this.$confirm('是否确认删除？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            this.$Axios._post({
                                url: this.$Config.apiUrl.backDeletePaymentDays,
                                params: {
                                    id: this.tableData[index].id
                                },
                            })
                            .then(res => {
                                if(res.result.code == 0){
                                    this.$message.success('操作成功')
                                    this.pageParam.freshCtrl++;
                                }
                            })
                            .catch((error) => {
                                this.$message.error(error.result.message)
                            });
                        }).catch(_=>{ })
                        break;
                    default:
                        break;
                }
            },
            zqAdd () {
                this.$refs['zqAdd'].dialogVisible = true
            },
            addZQSuccess () {
                this.pageParam.freshCtrl++
            },
            pzSuccess () {
                this.pageParam.freshCtrl++
            },
            // 编辑账期名称
            editZQName (datas) {
                this.$prompt('', '修改账期名称', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValue: datas.paymentName,
                    inputValidator: (val)=>{
                        if(!val){
                            return '请输入账期名称'
                        }
                    }
                }).then(({ value }) => {
                    this.$Axios._post({
                        url: this.$Config.apiUrl.backUpdatePaymentDays,
                        method: "post",
                        params: {
                            id: datas.id,
                            name: value
                        }
                    }).then(res => {
                        if(res.result.code == 0){
                            this.$message.success('操作成功')
                            this.pageParam.freshCtrl++;
                        }else{
                            this.$message.error(res.result.message)
                        }
                    }).catch(()=>{ })
                }).catch(() => {});
            },
            getZQInfo (datas) {
                this.$router.push({
                    path: '/finance/finance-zq-details',
                    query: {
                        id: datas.id,
                        startDate: datas.startDate,
                        endDate: datas.endDate
                    }
                })
            },
            //条件筛选
            filterFun(value){
                for(var key in value){
                    if(key=='incomeStatus'){
                        if(value[key].length==0){
                            this.pageParam.params.incomeStatus = ''
                        }else{
                            this.pageParam.params.incomeStatus = value[key][0]
                        }
                    }
                }
                this.$refs['financeZQ'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            exportfile () {
                // this.$exfile({
                //     code: 3,
                //     fileName: this.pageParam.params.queryStartDate + ' 至 ' + this.pageParam.params.queryEndDate + ' 站点经营分析',
                //     startTime: this.pageParam.params.queryStartDate,
                //     endTime: this.pageParam.params.queryEndDate,
                //     params: this.pageParam.params
                // })
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
    /deep/ .companySelect{
        .a-ml-20{
            margin-left: 10px !important;
        }
    }
</style>